@import "styles/variables";

.menu_parent {
	cursor: pointer;
}

.vertical_line {
	font-family: "Konnect";
	font-weight: 600;
	font-size: 1.5rem;
	display: flex;
}

.menu_text {
	font-weight: 800;
	letter-spacing: 1.5px;
	font-size: 0.7rem;
	text-transform: uppercase;
	margin-top: 0.8rem;
	display: flex;
	align-content: flex-end;
	flex-direction: column-reverse;
	padding-bottom: 7px;
	margin-left: 5px;
	margin-right: 5px;
}

.wallet {
	margin-top: 1rem;
	cursor: pointer;
	text-align: center;
	font-family: "Konnect";
	font-size: 0.8rem;
}

.fashion_index_logo {
	font-family: "Konnect";
	writing-mode: vertical-lr;
	transform: scale(-1, -1);
	position: fixed;
	top: 5vh;
	right: 2.3%;
	font-size: 1.8vw;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 3vw;
		right: 0%;
		top: 7vh;
	}

	@media (max-width: $breakpoint-mobile) {
		font-size: 5vw;
		right: 1%;
		top: 7vh;
	}
}
.marketplace_index_logo {
	font-family: "Konnect";
	writing-mode: vertical-lr;
	transform: scale(1, 1);
	position: fixed;
	top: 5vh;
	right: 5.3%;
	font-size: 1.8vw;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 3vw;
		right: 0%;
		top: 7vh;
	}

	@media (max-width: $breakpoint-mobile) {
		font-size: 5vw;
		right: 1%;
		top: 7vh;
	}
}

.marketplace_logo {
	font-family: "Konnect";
	writing-mode: vertical-lr;
	transform: scale(-1, -1);
	position: fixed;
	top: 5vh;
	right: 2.3%;
	font-size: 1.8vw;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 3vw;
		right: 0%;
		top: 7vh;
	}

	@media (max-width: $breakpoint-mobile) {
		font-size: 5vw;
		right: 1%;
		top: 7vh;
	}
}

.hape_or_not_logo {
	font-family: "Konnect";
	position: fixed;
	top: 5vh;
	right: 5%;
	font-size: 1.8vw;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 3vw;
		right: 0%;
		top: 7vh;
	}

	@media (max-width: $breakpoint-mobile) {
		font-size: 5vw;
		right: 1.8%;
		top: 7vh;
	}
}

.hape_or_not_logo {
	font-weight: 300;
}

.sidebar_token_id {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 35%;

	font-family: "Konnect";
	font-weight: 600;
	writing-mode: vertical-lr;
	transform: scale(-1, -1);
	position: fixed;
	bottom: 10%;
	right: 2.3%;
	font-size: 1.8vw;
	letter-spacing: 0.3rem;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 3vw;
		right: 0%;
		bottom: 19%;
	}

	@media (max-width: $breakpoint-mobile) {
		height: 25%;
		bottom: 20%;
		font-size: 3vw;
		right: 2%;
		width: 5%;
	}
}

.sidebar_hape_prime_text {
	font-weight: 500;
	text-transform: uppercase;
	font-size: 1vw;
	line-height: 100%;

	@media (max-width: $breakpoint-mobile) {
		font-size: 1.5vw;
	}
}

.bottom_icon {
	position: fixed;
	bottom: 2%;
	right: 6%;
	height: 1.5rem;

	padding: 0;
	margin: 0;
	border: 0;
	outline: 0;
	background-color: transparent;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		right: 1%;
		bottom: 8%;
	}

	@media (max-width: $breakpoint-mobile) {
		right: 2%;
		top: 70vh;
		bottom: 0;
	}
}

.bottom_icon_menu {
	position: fixed;
	bottom: 2%;
	right: 6%;
	height: 1.5rem;

	padding: 0;
	margin: 0;
	border: 0;
	outline: 0;
	background-color: transparent;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		right: 1%;
		bottom: 8%;
	}

	@media (max-width: $breakpoint-mobile) {
		right: 2%;
		top: 70vh;
		bottom: 0;
	}
}

.ham_menu {
	top: 2vh !important;
}

.raise {
	bottom: 6%;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		bottom: 14%;
	}

	@media (max-width: $breakpoint-mobile) {
		top: 66vh;
		bottom: 0;
	}
}

.is_unactivated {
	opacity: 40%;
}

.collapse_button {
	position: fixed;
	top: 50%;
	right: 6%;
	cursor: pointer;
	transform: rotate(90);

	@media (max-width: $breakpoint-tablet) {
		right: 1.3%;
		top: 50vh;
	}
}

.hape_logo {
	transform: rotate(-90deg);
	writing-mode: vertical-lr;
	width: 20vh;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.mobile_hape_logo_container {
	position: fixed;
	width: 10%;
	height: 26vh;
	background-color: black;
	right: 0%;
	top: 74vh;
}

.mobile_hape_logo {
	position: absolute;
	transform: rotate(-90deg);
	transform-origin: 0% 0%;
	width: 9vh;
	align-items: center;
	cursor: pointer;
	bottom: 0%;
	background-color: black;
	top: 12vh;
	left: 27%;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		width: 8vh;
		left: 62%;
	}
}

.menu {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	margin-top: 15%;
}

.links {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.link {
	margin-bottom: 1rem;
	width: 25%;

	background-color: transparent;
	border: 0;
	padding: 0;
}

.about_link {
	width: 10%;
}

.utility_bar {
	width: $utility-bar-width;
	height: 100%;
	color: white;
	height: 100%;
	position: fixed;
	background-color: #202020;
	z-index: 1000;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.utility_top {
	height: 70%;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 5% 15%;
}

.utility_bottom {
	height: 30%;
	width: 100%;
	background-color: black;

	display: flex;
	justify-content: center;
	align-items: center;
}

.filter_bar {
	display: block;
	width: $filter-bar-open-width;
	height: 100%;
	color: white;
	height: 100%;
	position: fixed;
	background-color: #262626;
	transition: 0.6s;
	z-index: 999;
	overflow: hidden;

	@media (max-width: $breakpoint-tablet) {
		width: 100%;
	}

	@media (max-width: $breakpoint-mobile) {
		top: 2.8rem;
	}
}

.filter_white_bar {
	display: block;
	width: $filter-bar-open-width;
	height: 100%;
	color: white;
	height: 100%;
	position: fixed;
	background-color: #fff !important;
	transition: 0.6s;
	z-index: 999;
	overflow: hidden;

	@media (max-width: $breakpoint-tablet) {
		width: 100%;
	}
}

.filter_bar_closed {
	transform: translate(-($filter-bar-translate), 0);
	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		transform: translate(-($tablet-filter-bar-translate), 0);
	}
	@media (max-width: 540px) {
		transform: translate(-94%, 0);
		border-right: 1px solid #000;
	}

	@media (max-width: $breakpoint-mobile) {
		transform: translate(-($mobile-filter-bar-translate), 0);
	}
}

.filter_bar_open {
	@media (min-width: ($breakpoint-tablet + 1px)) {
		transform: translate($filter-bar-translate, 0);
	}
}

.content_container {
	display: block;

	@media (min-width: $breakpoint-tablet) {
		margin-left: $content-container-margin-left;
	}

	// @media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
	// 	margin-left: $tablet-filter-bar-closed-width;
	// }

	// @media (max-width: $breakpoint-mobile) {
	// 	margin-left: $mobile-filter-bar-closed-width;
	// }
}

.footer {
	width: 100%;
	height: 100px;
	border-top: 1px solid #eaeaea;
	display: flex;
	justify-content: center;
	align-items: center;

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-grow: 1;
	}
}

.gradient_animation {
	background: linear-gradient(304deg, #f5cb77, #e79a26, #ffe055, #d19444);
	background-size: 250% 250%;
	-webkit-animation: gradient 4s linear infinite;
	animation: gradient 4s linear infinite;
	-moz-animation: gradient 4s linear infinite;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.main-container {
	max-width: 100vw;
}

.background_wipe {
	top: 0;
	left: 0;
	bottom: 0;
	background: #fff;
}

.background {
	background-color: transparent;
}

.early_button {
	border: 0;
	border-radius: 25px;
	padding: 0.5rem 1rem;
	font-family: "Konnect";
	margin-bottom: 0.25rem;
}

.tab {
	color: white;
	border-top-right-radius: 16px;
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: 5px;
	cursor: pointer;
}

.tab-active {
	font-weight: bold;
}

.tabs_container {
	display: flex;
	justify-content: center;
}

.eyes {
	cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='65' height='78' viewport='0 0 100 100' style='fill:black;font-size:39px;'><text y='50%'>👀</text></svg>")
			16 0,
		auto; /*!emojicursor.app*/
}

.connect_wallet_container {
	text-align: right;
}
.connect_wallet {
	border: 0;
	background-color: #dfdfdf;
	color: black;
	font-weight: bold;
	border-radius: 13px;
	font-size: 20px;
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 20px;
	cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='65' height='78' viewport='0 0 100 100' style='fill:black;font-size:39px;'><text y='50%'>👀</text></svg>")
			16 0,
		auto !important; /*!emojicursor.app*/
}
