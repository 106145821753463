.top_filter_navbar {
	background: #fff;
	z-index: 999;
	position: relative;
	height: 2.8rem;
	.flex_wrap {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 1rem;
		.right_logos {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 5px;
			.spacing {
				padding: 0rem 0.5rem;
				display: flex;
			}
		}
	}
}

.fashion_index_theme {
	background-color: #202020;
	position: fixed;
	width: 100%;
}

.collapse_button {
	transform: rotate(90deg);
	display: flex;
	margin-right: 0.4rem;
}