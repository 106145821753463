@import "styles/variables";

.pane {
	position: fixed;
	height: 100%;
	width: 100% - $content-container-margin-left;
	right: 100%;
	bottom: 0;
	z-index: 888;
	background-color: rgba(255, 255, 255, 0.7);
	color: black;
	margin-left: $content-container-margin-left;
	opacity: 0;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		width: 100% - $tablet-filter-bar-closed-width;
		margin-left: $tablet-filter-bar-closed-width;
	}

	@media (max-width: $breakpoint-mobile) {
		width: 100% - $mobile-filter-bar-closed-width;
		margin-left: $mobile-filter-bar-closed-width;
	}
	@media (max-width: 450px) {
		width: 100%;
		margin-left: 0%;
		top: 5%;
	}
}

.behind {
	position: fixed;
	width: 100vw;
	height: 130vh;
	top: 5%;
	right: 100%;
	background-color: white !important;
}

.stats_box_container {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	flex-direction: column;
	margin-bottom: 1rem;
}

.stats_box_parent {
	position: relative;
	text-align: center;
}

.top_100_container {
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}

.top_100_week_1 {
	font-family: "Konnect";
	background: linear-gradient(89.82deg, #47b7cf 2.39%, #4c72d5 99.84%);
	border-width: 1px 1px 0px 1px;
	border-style: solid;
	border-color: rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	color: white;
	width: 45%;
	text-align: center;
	margin-bottom: 10px;
	margin-top: 10px;
	font-size: 0.9rem;
}

.top_100_week_2 {
	background: linear-gradient(89.82deg, #e46262 2.39%, #980000 99.84%);
}

.points_votes_container {
	font-family: "Konnect";
	font-weight: 300;
	display: flex;
	justify-content: space-between;
	text-align: center;
	color: white;
	width: 23rem;
	margin: 0 auto;

	@media (max-width: $breakpoint-mobile) {
		width: 100%;
		font-size: 0.7rem;
	}
}

.value {
	font-weight: 400;
	font-size: 1.5rem;

	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;

	@media (max-width: $breakpoint-mobile) {
		font-size: 1rem;
	}
}

.vote_heart_container {
	display: flex;
}

.vote_heart_right {
	margin-right: 0.3rem;
}

.vote_heart {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ranking_indicator_container {
	position: absolute;
	top: 50%;
	left: -2rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 1.5rem;
	font-size: 0.8rem;
	transform: translateY(-50%);

	@media (max-width: $breakpoint-mobile) {
		top: 50%;
		transform: translateY(-50%);
	}
}

.flip {
	transform: rotate(180deg);
}

.arrow {
	transition: opacity 0.5s;

	@media (max-width: $breakpoint-mobile) {
		width: 1rem;
	}
}

.score {
	font-family: "Konnect";
	font-weight: 300;
	font-size: 1.3rem;

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 1.2rem;
	}

	@media (max-width: $breakpoint-mobile) {
		font-size: 1.1rem;
	}
}

.rank_title {
	font-size: 1.3rem;
	font-weight: 500;

	@media (max-width: $breakpoint-mobile) {
		font-size: 0.8rem;
	}
}

.rank {
	font-family: "Konnect";
	font-style: normal;
	font-size: 11rem;
	font-weight: 600;
	line-height: 85%;
	-webkit-text-stroke: 1px white;
	color: transparent;
	filter: blur(2px);

	@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
		font-size: 8rem;
	}

	@media (max-width: $breakpoint-mobile) {
		font-size: 6rem;
	}
}

.lift_blur {
	filter: none;
}

.modal {
	$right_padding_all: 2rem;
	$modal_height: 30vw;
	$modal_width: 60vw;
	$border_gold: #ae8829;
	$modal_breakpoint: 1024px;
	$modal_border_radius: 22px;

	@mixin all_caps {
		font-size: 0.7vw;
		letter-spacing: 0.15rem;
		text-transform: uppercase;
	}

	&__blur {
		filter: blur((2px));
	}

	&__align_items_center {
		align-items: center;
	}

	&__files_connect_parent {
		display: flex;
		justify-content: center;
		width: 100%;
		height: 85%;
		margin-top: 1rem;

		@media (max-width: $modal_breakpoint) {
			height: 70%;
		}
	}

	&__mobile_equip_box {
		background-color: #202020;
		position: relative;
		margin-top: 0;

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			height: 100%;
		}

		@media (max-width: $breakpoint-mobile) {
		}
	}

	&__files_connect {
		height: 100%;
		width: 95%;
		overflow-y: auto;
	}

	&__data_rule_num {
		display: flex;
		flex-direction: column;
		justify-content: left;
		text-align: left;
		flex: 0 0 3.4em;
	}

	&__data_rule_data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: right;
		flex: 0 0 3.2em;
	}

	&__data_trend_score_multiplier {
		display: block;
		font-size: 80%;
	}

	&__data_trend_score {
		display: block;
		font-weight: bold;
		font-size: 110%;
	}

	&__data_trend_container {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-top: 0.65rem;
		padding-bottom: 0.65rem;
		overflow-y: auto;
		height: 70rem;
		margin-bottom: 0.5rem;
	}

	&__data_header {
		display: flex;
		justify-content: space-between;
		color: white;
		font-weight: bold;
		font-size: 0.8vw;
		letter-spacing: 1px;
		vertical-align: middle;

		@media (max-width: $modal_breakpoint) {
			font-size: 0.7em;
		}

		@media (max-width: 600px) {
			font-size: 0.6em;
		}
	}

	&__data_trait_container {
		padding: 14px;
		width: 100%;
		border: 1px solid rgba(255, 255, 255, 0.2);
		border-top: 0px;

		&:hover {
			background-color: rgb(255, 255, 255, 0.2);
		}
	}

	&__top {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
	}

	&__data_rule_container {
		display: flex;
		color: white;
		font-size: 0.9vw;
		text-transform: uppercase;
		margin-top: 0.5rem;
		justify-content: space-between;
		overflow-y: auto;

		// @media (max-width: $modal_breakpoint) {
		// 	font-size: 1.7vw;
		// }

		@media (max-width: 600px) {
			font-size: 0.5em;
		}
	}

	&__data_multiplier_sacRuleTwo_div {
		flex: 0 0 18em;

		@media (max-width: $modal_breakpoint) {
			flex: 0 0 30em;
		}

		@media (max-width: 600px) {
			flex: 0 0 25em;
		}
	}

	&__data_multiplier_indiv {
		background-color: #333333;
		padding: 2px;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 3px;
		margin-bottom: 3px;
		color: white;
		display: inline-block;
		flex: 0 0 18em;

		@media (max-width: $modal_breakpoint) {
			flex: 0 0 30em;
		}

		@media (max-width: 600px) {
			flex: 0 0 25em;
		}
	}

	&__data_only_traits {
		overflow-y: auto;
		max-width: 100%;
		flex-wrap: wrap;
		display: flex;
		flex-direction: row;
		padding-left: 2rem;
		padding-right: 2rem;

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			padding-left: 0;
			padding-right: 0;
		}

		@media (max-width: $breakpoint-mobile) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__only_traits {
		overflow-y: auto;
		max-width: 100%;
		flex-wrap: wrap;
		display: flex;
		flex-direction: row;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	&__question {
		background-color: transparent;
		border: 0;
		color: white;
	}

	&__close_container {
		z-index: 999;
		cursor: pointer;
	}

	&__close_button {
		border: 0;
		background-color: transparent;
		color: white;
		padding-top: 0.5rem;
	}

	&__proceed {
		border-radius: 20px;
		border: 0;
		background-color: white;
		color: black;
		padding: 0.3rem 0.8rem;
	}

	&__modal_body {
		height: 100%;
		position: relative;
		flex: 1 1 auto;
		animation: gradient 8s cubic-bezier(0.4, 0, 1, 1) infinite;
		background-size: 300% !important;
		background-color: white !important;
	}

	&__modal_lg_container {
		@media (min-width: $modal_breakpoint) {
			max-width: $modal_width;
			margin: $modal_height/3.5 auto !important;
		}

		@media (max-width: $modal_breakpoint) {
			max-width: 55vw;
			margin: $modal_height/9 auto !important;
		}

		@media (max-width: 600px) {
			max-width: 93vw;
		}

		@media (max-width: 500px) {
			// margin: 23vw auto !important;
		}
	}

	&__modal_content {
		position: relative;
		display: flex;
		flex-direction: column;
		height: 100%;
		width: 100%;
		pointer-events: auto;
		background-clip: padding-box;
		border-radius: 0.3rem;
		outline: 0;
	}

	&__hape_container {
		// Use box-shadow instead of border to prevent space between
		// highlight and border on Chrome
		display: flex;
		height: 100%;
		overflow: hidden;
		flex-direction: row;

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			flex-direction: column;
		}

		@media (max-width: $breakpoint-mobile) {
			flex-direction: column;
		}
	}

	&__left_side {
		position: relative;
		width: 60%;
		text-align: center;
		margin-left: auto;
		margin-right: auto;

		height: 100%;
		transition: height 0.75s;

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			height: 50%;
			width: 100%;
		}

		@media (max-width: $breakpoint-mobile) {
			height: 50%;
			width: 100%;
		}
	}

	&__export {
		position: absolute;
		bottom: 1rem;
		left: 1rem;

		padding: 0;
		margin: 0;
		border: 0;
		background-color: transparent;

		@media (max-width: $breakpoint-mobile) {
			bottom: 0.5rem;
			left: auto;
			right: 0.5rem;
			width: 1.7rem;
			margin: 0.5rem;
		}
	}

	&__left_side_equip {
		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			height: 100%;
		}

		@media (max-width: $breakpoint-mobile) {
			height: 100%;
		}
	}

	&__left_side_rarity_rank {
		display: flex;
		align-items: baseline;
		flex-direction: row;
		color: rgb(71, 0, 0);
		overflow: hidden;
		font-size: 1.125rem;
	}

	&__rarity_rank_text {
		font-weight: bold;
		white-space: nowrap;
	}

	&__rarity_middle {
		text-align: right;
		flex-grow: 1;
	}

	&__owner_text {
		cursor: pointer;
		overflow: hidden;
		margin-left: 0.25rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		display: block;
	}

	&__hape_id_container {
		margin-top: 0.5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	&__hape_id {
		color: rgb(221, 221, 221);
		text-align: left;
		line-height: 1.75rem;
		font-weight: 700;
	}

	&__hape_slash {
		color: rgb(221, 221, 221);
		line-height: 1.75rem;
		font-weight: 700;
		margin-left: 10px;
		margin-right: 10px;
	}

	&__opensea_button {
		display: flex;
		flex-direction: row;
		vertical-align: middle;
		justify-content: center;
		align-content: center;
		align-items: center;
		border: 0;
		width: 100%;
		font-style: normal;
		font-weight: bold;
		background-color: transparent;
	}

	&__hape_pfp_outside {
		display: flex;
		justify-content: center;
		height: 100%;
		transition: 0.8s;
		position: relative;
	}

	&__hape_pfp {
		border-radius: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		transition: 0.8s;
	}

	&__layered_extra {
		height: 100%;
		width: 100%;

		@media (max-width: $breakpoint-tablet) {
			height: unset;
		}
	}

	&__dynamic_pfp {
		bottom: 0;
		width: 109vh;
		opacity: 1;
		height: 109vh;

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			width: 50vh;
			height: 50vh;
		}

		@media (max-width: $breakpoint-mobile) {
			width: 45vh;
			height: 45vh;
		}
	}

	&__mobile_equip_dynamic_pfp {
		bottom: 0;
		opacity: 1;
		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			width: 65vh;
			height: 65vh;
		}

		@media (max-width: $breakpoint-mobile) {
			width: 85vh;
			height: 85vh;
		}
	}

	&__mobile_equip_hape_pfp_background {
		position: absolute;
		bottom: 0;
		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			width: 65vh;
		}

		@media (max-width: $breakpoint-mobile) {
			width: 85vh;
		}
	}

	&__hape_pfp_background {
		position: absolute;
		bottom: 0;
		width: 109vh;
		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			width: 50vh;
			height: 50vh;
		}

		@media (max-width: $breakpoint-mobile) {
			width: 45vh;
			height: 45vh;
		}
	}

	&__hape_tablet_left_extra {
		position: absolute;
		bottom: 0;
		width: 109vh;
		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			width: 50vh;
			height: auto;
		}
	}

	&__layered_extra_background {
		position: absolute;
		bottom: 0;
		width: 109vh;

		@media (min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
			height: 100%;
			width: 100%;
		}

		@media (max-width: $breakpoint-mobile) {
			height: 100%;
			width: auto;
		}
	}

	&__hape_pfp_background > div {
		display: block !important;
	}

	&__hape_pfp_background > div {
		aspect-ratio: 1/1;
	}

	&__hape_pfp_background > video {
		aspect-ratio: 1/1;
	}

	&__hape_pfp_background_2 {
		position: absolute;
		bottom: 0;
		width: 109vh;
		opacity: 1;
		transition: opacity 0.5s;
		transition-property: opacity;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 500ms;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			width: 50vh;
		}

		@media (max-width: $breakpoint-mobile) {
			width: 45vh;
		}
	}

	&__hape_pfp_background_2 > div {
		display: block !important;
	}

	&__hape_pfp_background_2 > div {
		aspect-ratio: 1/1;
	}

	&__right_extra {
		left: 100%;
	}

	&__left_extra {
		left: -100%;
	}

	&__top_extra {
		bottom: 100%;
	}

	&__hape_layered {
		position: absolute;
	}

	&__mobile_stats_box {
		bottom: 0;
	}

	&__move_to_top {
		top: 10%;
	}

	&__badge_video {
		height: 100%;
		width: 100%;
		pointer-events: none;
	}

	&__right_side {
		width: 50%;
		height: 100%;
		z-index: 20;
		background: linear-gradient(
			270.84deg,
			rgba(0, 0, 0, 0.3) 0.56%,
			rgba(0, 0, 0, 0) 99.13%
		);

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			height: 50%;
			width: 100%;
		}

		@media (max-width: $breakpoint-mobile) {
			height: 50%;
			width: 100%;
		}
	}

	&__equip_width {
		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			height: 10%;
		}
		@media (max-width: $breakpoint-mobile) {
			height: 20%;
		}
	}

	&__right_side_inner_container {
		display: flex;
		flex-direction: column;
		height: 90%;

		@media (min-width: ($breakpoint-mobile + 1px)) and (max-width: $breakpoint-tablet) {
			height: 100%;
			justify-content: center;
		}
		@media (max-width: $breakpoint-mobile) {
			height: 100%;
		}
	}

	// deprecated
	&__right_side_trait_section {
		@media (min-width: $modal_breakpoint) {
			color: black;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			height: $modal_height;
		}

		@media (max-width: $modal_breakpoint) {
			height: 35vw;
		}
	}

	&__right_side_dna {
		font-size: 1vw;

		border-top: 1px;
		color: white;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 2rem;
		padding-right: 2rem;

		@media (min-width: $modal_breakpoint) {
			padding-bottom: 5px;
		}
	}

	&__right_side_dna > div {
		display: flex;
		align-items: center;
		width: 7rem;
	}

	&__birthday_text {
		margin-left: 10px;
		font-size: 0.75rem;
	}

	&__dna_text {
		margin-right: 10px;
		font-size: 0.65rem;
		justify-content: flex-end;
	}

	&__right_side_inner {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 100%;
		margin: auto;
		text-align: center;
		border-radius: 50px;
		height: 3rem;
		padding-left: 0.75rem;
		padding-right: 0.75rem;

		background: linear-gradient(304deg, #f5cb77, #e79a26, #ffe055, #d19444);
		background-size: 600% 600%;

		animation: gradient 4s linear infinite;
		-webkit-animation: gradient 4s linear infinite;
		-moz-animation: gradient 4s linear infinite;
	}

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}

	&__rarity_score_title {
		text-align: center;
		border-width: 1px;
		border-radius: 0.5rem;
		color: white;
		font-weight: bold;
		text-shadow: -2px 2px 5px rgba(85, 85, 85, 0.3);
	}

	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		50% {
			background-position: 100% 50%;
		}
		100% {
			background-position: 0% 50%;
		}
	}

	&__trait_container {
		margin-bottom: 0.5rem;
		border-radius: 10px;
		padding: 6px;
		min-width: 50%;
		max-width: 50%;
	}

	&__trait_title {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		overflow: hidden;
		line-height: 1.25rem;
		// align-items: center;
	}

	&__trait_category {
		display: flex;
		flex-direction: column;
		overflow-wrap: anywhere;
		flex-grow: 1;
		flex-shrink: 10;
		color: white;
	}

	&__trait_category_text {
		font-family: "Konnect";
		font-size: 0.6vw;
		font-weight: 300;
		line-height: normal;
		text-transform: uppercase;
		letter-spacing: 3px;
		color: white;

		@media (max-width: $modal_breakpoint) {
			font-size: 0.9vw;
		}

		@media (max-width: 600px) {
			font-size: 2vw;
		}

		@media (max-width: 400px) {
			font-size: 2.3vw;
		}
	}

	&__trait_value_text {
		font-family: "Konnect";
		font-size: 1.1vw;
		line-height: normal;
		font-weight: 500;
		color: rgb(255, 255, 255);

		@media (max-width: $modal_breakpoint) {
			font-size: 1.5vw;
		}

		@media (max-width: 600px) {
			font-size: 2.5vw;
		}

		@media (max-width: 400px) {
			font-size: 3vw;
			line-height: 3.5vw;
		}
	}

	&__trait_eth {
		margin-left: 0.5rem;
		margin-right: 0.5rem;
		color: green;
	}

	&__trait_score {
		font-family: "Konnect";
		font-weight: 300;
		display: flex;
		align-items: center;
		text-align: right;
		padding-left: 0.25rem;
		font-size: 1rem;
		color: white;
		letter-spacing: 2px;
	}

	&__rarity_type {
		font-family: "Konnect";
		font-weight: 300;
		display: flex;
		align-items: center;
		text-align: right;
		padding-left: 0.25rem;
		font-size: 0.7rem;
		color: white;
		letter-spacing: 2px;
		text-transform: uppercase;
	}

	&__trait_listing {
		display: flex;
		flex-direction: row;
		color: white;
		padding-left: 0.375rem;
		overflow: hidden;
		margin-bottom: 0.125rem;
		font-size: 0.875rem;
		line-height: 1.25rem;
		border-width: 1px;
		border-radius: 0.375rem;
		background-color: gray;
	}

	&__trait_name {
		overflow: hidden;
		flex-grow: 1;
		margin-top: 2px;
	}

	&__trait_quantity {
		width: 2.6rem;
		color: gray;
		text-align: left;
		margin-right: 0.45rem;
		font-weight: 500;
		border-radius: 0.25rem;
	}

	&__icon_category {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.6rem;
		margin-right: 10px;
	}

	&__backdrop {
		background: rgba(0, 0, 0, 0.65);
		bottom: 0;
		left: 0;
		overflow: auto;
		position: fixed;
		right: 0;
		top: 0;
		z-index: 1;
	}

	&__buttons {
		text-align: right;
	}

	&__edit_button {
		border: 0;
		display: inline;
		margin-right: 10px;
		background-color: #b1b1b1;
		border-radius: 5px;
		color: white;
		font-weight: bold;
	}

	&__cancel_button {
		background-color: rgb(0, 0, 0);
	}

	&__update_button {
		background-color: rgb(0, 80, 55);
	}

	&__headline {
		display: inline;
	}

	&__long_bio {
		width: 100%;
		height: 100px;
	}

	&__width_100 {
		width: 100%;
	}

	&__about_me {
		white-space: break-spaces;
	}

	&__discord_username {
		color: #7289da;
		font-size: 0.9rem;
		vertical-align: super;
	}

	&__twitter_username {
		color: #1da1f2;
		font-size: 0.9rem;
		vertical-align: super;
		cursor: pointer;
	}

	&__job {
		font-size: 1rem;
	}

	&__edit_header {
		font-weight: bold;
	}

	&__validation {
		font-size: 12px;
		color: red;
		font-weight: bold;
	}

	&__loading {
		color: rgb(0, 80, 55);
		font-weight: bold;
	}

	&__location_container {
		display: flex;
		justify-content: space-evenly;
	}

	&__asterisk {
		color: red;
		font-weight: bold;
	}

	&__avatar {
		height: 120px;
		width: 120px;
		background-color: rgb(255, 255, 255);
		border-color: #d2dbdd;
		border-style: solid;
		border-width: thick;
		border-radius: 50%;
	}

	&__tag_container_mentor {
		background-color: rgb(250, 192, 0);
		display: inline-block;
		border-radius: 10px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 1px;
		padding-bottom: 1px;
		font-size: 1.3vh;
		margin: 2px;
	}

	&__tag_container_team {
		background-color: rgb(239, 58, 255);
		display: inline-block;
		border-radius: 10px;
		padding-left: 5px;
		padding-right: 5px;
		padding-top: 1px;
		padding-bottom: 1px;
		font-size: 1.3vh;
		margin: 2px;
	}

	&__fade_in {
		animation: fadein 0.5s linear;
		-webkit-animation: fadein 0.5s linear;
		-moz-animation: fadein 0.5s linear;
	}

	&__fade_out {
		animation: fadeout 0.5s linear forwards;
		-webkit-animation: fadeout 0.5s linear forwards;
		-moz-animation: fadeout 0.5s linear forwards;
	}

	@keyframes fadein {
		0% {
			opacity: 0%;
		}
		100% {
			opacity: 100%;
		}
	}

	@-moz-keyframes fadein {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@-webkit-keyframes fadein {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	@keyframes fadeout {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@-moz-keyframes fadeout {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	@-webkit-keyframes fadeout {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	&__tab_container {
		align-content: center;
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-left: 10%;
		margin-right: 10%;
		transition: 0.8s;
		position: relative;
		padding-top: 0.5rem;
	}

	&__tab_container_no_margins {
		margin-left: 0;
		margin-right: 0;
	}

	&__tab_container button {
		$tab_x_padding: 1.5rem;

		background: none;
		color: white;
		border: none;
		padding: 0;
		font: inherit;
		cursor: pointer;

		@include all_caps;
		padding: 1.2rem $tab_x_padding 0.7rem;
		position: relative;
		z-index: 3;

		@media (min-width: $modal_breakpoint) {
			padding: 0.7rem $tab_x_padding 0.2rem;
			margin-bottom: 0.8rem;
		}

		@media (max-width: $modal_breakpoint) {
			font-size: 1.2vw;
		}

		@media (max-width: 600px) {
			font-size: 1.8vw;
		}
	}

	&__disabled_tab_button {
		&:hover {
			cursor: not-allowed !important;
		}
	}

	&__selected_tab {
		position: relative;
	}

	&__selected_tab > button {
		// Use text shadow instead of bold to prevent text shifting when
		// tab switches.
		text-shadow: 0 0 1px white;
		font-weight: bold;
	}

	&__selected_tab > span {
		border-radius: 0.75rem;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transform: perspective(20px) translateY(0.75rem) rotateX(10deg);
		width: 100%;
		z-index: 2;

		@media (min-width: $modal_breakpoint) {
			transform: perspective(20px) translateY(-0.75rem) rotateX(-10deg);
		}
	}
}
