$utility-bar-width: 8%;
$filter-bar-closed-width: 4%;
$filter-bar-open-width: 20%;
$content-container-margin-left: $utility-bar-width + $filter-bar-closed-width;
$filter-bar-closed-position-left: $filter-bar-open-width -
	$filter-bar-closed-width - $utility-bar-width;

$tablet-filter-bar-closed-width: 5%;
$mobile-filter-bar-closed-width: 10%;

$filter-bar-translate: 40%;
$tablet-filter-bar-translate: 95%;
$mobile-filter-bar-translate: 90%;

$breakpoint-tablet: 1000px;
$breakpoint-mobile: 500px;
